import React, { useEffect, useRef, useState } from "react";
import { sumArray } from "../../helper";
import "./Projects.css";
import { projects } from "../../data";
import Card from "./Card";

const tabs = [
  { name: "All" },
  { name: "Apps" },
  { name: "Project" },
  { name: "School" },
];

const Projects = () => {
  const [displayableProjects, setDisplayableProjects] = useState(projects);
  const [activeIndex, setActiveIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3); // State to control number of visible projects
  const [visibleProjects, setVisibleProjects] = useState([]); // State to store visible projects
  const [initialVisibleCount, setInitialVisibleCount] = useState(3); // State to store initial visible count

  const projectSectionRef = useRef(null); // Reference to project section
  const itemEls = useRef([]);

  useEffect(() => {
    // Calculate the position and width of the indicator based on active tab
    const prevEl = itemEls.current.filter((_, index) => index < activeIndex);
    setOffset(sumArray(prevEl.map((item) => item.offsetWidth)));
    setIndicatorWidth(itemEls.current[activeIndex]?.offsetWidth || 0);
  }, [activeIndex]);

  useEffect(() => {
    // Set projects based on active tab
    setProjects(tabs[activeIndex].name);
  }, [activeIndex]);

  useEffect(() => {
    // Update visible projects based on the current visible count
    setVisibleProjects(displayableProjects.slice(0, visibleCount));
  }, [displayableProjects, visibleCount]);

  const setProjects = (category) => {
    // Filter projects based on category
    if (category === "All") {
      setDisplayableProjects(projects);
    } else {
      const filteredProjects = projects.filter(
        (item) => item.category.toLowerCase() === category.toLowerCase()
      );
      setDisplayableProjects(filteredProjects);
    }
    setInitialVisibleCount(3); // Reset initial visible count on tab change
    setVisibleCount(3); // Reset visible count on tab change
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleShowLess = () => {
    setVisibleCount((prevCount) => Math.max(initialVisibleCount, prevCount - 3)); // Decrease by 3 until reaching initial count
    // Scroll smoothly to the top of the project section
    projectSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section id="projects" ref={projectSectionRef}>
      <div className="section__wrapper projects__container">
        <div className="section__header center">
          <h2 className="primary__title__2">Projects</h2>
        </div>
        <nav>
          {tabs.map((tab, index) => (
            <button
              ref={(el) => (itemEls.current[index] = el)}
              key={index}
              onClick={() => {
                setActiveIndex(index);
              }}
              className={activeIndex === index ? "active" : ""}
            >
              {tab.name}
            </button>
          ))}
          <span
            className="active__indicator"
            style={{
              left: `${offset}px`,
              width: `${indicatorWidth}px`,
            }}
          ></span>
        </nav>
        <div className="card__container">
          {visibleProjects.map((project, index) => (
            <Card
              key={index}
              title={project.title}
              image={project.image}
              data={project.data}
              stack={project.stack}
              demoLink={project.data.demoLink}
            />
          ))}
        </div>
        <div className="see-more-container">
          {/* Show More Button */}
          {visibleProjects.length < displayableProjects.length && (
            <button className="see-more-btn" onClick={handleShowMore}>
              Show More Projects
            </button>
          )}
          {/* Show Less Button */}
          {visibleProjects.length > initialVisibleCount && (
            <button className="see-more-btn" onClick={handleShowLess}>
              Show Less
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Projects;
